



































































.v-alert.global-notice {
  height: auto;
  min-height: 60vh;

  &.sm-and-down {
    padding-top: 120px;
    padding-bottom: 60px;
  }

  ::v-deep p {
    font-size: 20px;
    letter-spacing: 0.012em;
  }
}

.fade-slow-enter-active,
.fade-slow-leave-active {
  transition: opacity 1s;
}
.fade-slow-enter,
.fade-slow-leave-active {
  opacity: 0;
}
.fade-slow-enter-active {
  transition-delay: 1s;
}
